import moment from "moment";
import { ObjectType } from "typescript";
import WebService from "./WebService";
import { toast } from "react-toastify";
const HelperService = {
  getFormatedDateForDetail(dt: any, format?: any) {
    var stillUtc = moment.utc(dt).toDate();
    if (dt) {
      if (format) {
        return moment(stillUtc).local().format(format);
      } else {
        return moment(stillUtc)
          .local()
          .format(
            (localStorage.getItem("date_format") || "DD-MM-YYYY") + " hh:mm A"
          );
      }
    }
  },

  getFormatedDate(dt: any, format?: any) {
    var stillUtc = moment.utc(dt).toDate();
    if (dt) {
      if (format) {
        return moment(stillUtc).local().format(format);
      } else {
        return moment(stillUtc)
          .local()
          .format(
            (localStorage.getItem("date_format") || "YYYY-MM-DD")
          );
      }
    }
  },

  isEmptyObject(data: object) {
    return Object.keys(data).length === 0;
  },
  allowOnlyNumericValue(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 11) {
      e.preventDefault();
      return false;
    }
  },
  allowOnlyNumericValue10(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode !== 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 9) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericAndCharacter(e: any) {
    const allowedCharacters = /^[a-zA-Z0-9]$/; 
    if (!e.key.match(allowedCharacters) && e.keyCode !== 8) { 
      e.preventDefault();
      return false;
    }
    if (e.currentTarget.value.length > 40) {
      e.preventDefault();
      return false;
    }
  },
  allowOnlyCharacter(e: any) {
    const allowedCharacters = /^[a-zA-Z ]$/; 
    if (!e.key.match(allowedCharacters) && e.keyCode !== 8) { 
      e.preventDefault();
      return false;
    }
    if (e.currentTarget.value.length > 40) {
      e.preventDefault();
      return false;
    }
  },
  
  allowOnlyNumericPincode(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode !== 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 5) {
      e.preventDefault();
      return false;
    }
  },
  maxNumber(e: any, maxNumber: number) {
    if (maxNumber && e.currentTarget.value.length > maxNumber) {
      e.preventDefault();
      return false;
    }
  },
  getRoleValue(val: string) {
    switch (val) {
      case "ADMIN":
        return "Admin";
      case "MANAGER":
        return "Manager";
      case "SERVICE_ENGINEER":
        return "Service Engineer";
      default:
        return "N/A";
    }
  },

  getPermissionValue(val: string) {
    switch (val) {
      case "VIEW":
        return "View";
      case "VIEW_EDIT":
        return "View Edit";
      case "ALL":
        return "ALL";
      default:
        return "N/A";
    }
  },

  getWebSocketIP() {
    return "wss://ws.energibharat.com/EVB-P20261797"
    // return "ws://localhost:8088"
    // return "ws://ec2-13-202-37-64.ap-south-1.compute.amazonaws.com:8088"
  },

 statusList(status: string) {
    if (status === "Available" || status === "Active" || status === "Completed") {
      return (
        <span className="badge text-success font-14 fw-bolder">{status}</span>
      );
    } else if (status === "Cancelled") {
      return (
        <span className="badge text-danger font-14 fw-bolder">{status}</span>
      );
    } else if (status === "Partially Active" || status === "Received") {
      return (
        <span className="badge text-warning font-14 fw-bolder">{status}</span>
      );
    } else if (status === "REFUNDED_IN_WALLET"){
      return (
        <span className="badge text-success font-14 fw-bolder">Refunded</span>
      );
    } else {
      return (
        <span className="badge  text-secondary font-14 fw-bolder">{status}</span>
      );
    }

  }

};

export default HelperService;
